import React from "react"
import SEO from "../../components/seo"
import Success from "../../components/TheBirraBehind/Success"

export default function SuccessPage({}) {
  return (
    <>
      <SEO title="The Birra behind the music success" />
      <Success />
    </>
  )
}
